<template>
      <div class="calendar-wrapper">
        <div class="calendar-blur left"></div>
        <div class="calendar-container" ref="calendarContent">
            <div class="calendar-box" :class="{selected: o.selected}" v-for="(o, index) in calendar" :key="index" @click="selectMatchByDate(o)">
            <span class="calendar-box__week">{{$t(o.day)}}</span>
            <span class="calendar-box__monnth">{{o.date}}<span>{{ addCNCalendarDay }}</span> {{$t(o.month)}}</span>             
            </div>
        </div>
        <div class="calendar-blur right" v-show="!isShowPassDayOnly"></div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/js/config.js";
const TODAY = "TODAY";
export default {
    props: {
        params: {
            type: String
        },
        show: {
            type: Number,
            default: 7
        },
        data: {
            type: Object,
        },
        isShowPassDayOnly: {
            type: Boolean,
            default: false
        }
    },
    watch:{
		params: {
			deep: true,
            handler(newVal,oldVal) {    
                this.syncWeeklyCalendar(newVal);				
            }
		},
        data: {
            deep: true,
            handler(newVal,oldVal) {       
             let isOverWeeklyDateRange = true;

                for(let i = 0; i < this.calendar.length; i++) {
                    if (newVal.isLiveOnlyMatch) {
                        this.calendar[i].selected = false;
                    } else{                   
                        if (newVal.currentDate === this.calendar[i].fullDate) {
                            this.calendar[i].selected = true;
                            isOverWeeklyDateRange = false;               
                        } else {
                            this.calendar[i].selected = false;
                        }    
                        this.$emit('isOverWeeklyDateRange',isOverWeeklyDateRange);
                             
                    }
                }		
            }
        }
    },
    computed: {
        ...mapGetters([
            "currentLocale",
        ]),
        addCNCalendarDay() {
            if (this.cnLocaleList.includes(this.currentLocale)) {
                return "日";
            }
            return;
        },
    },
    data() {
        return{
            calendar : [],
            currentTodayDate : '',
            cnLocaleList: config.cnLocaleList,
        }
    },

    mounted() {
        this.initCalendar();
    },

    methods: {
        
        selectMatchByDate(o) {
            this.calendar.forEach(x => {
                x.selected = false;
            });
            o.selected = true;

            this.$emit('weeklyDateChange',o)
        },

        syncWeeklyCalendar(newVal) {
            let isOverWeeklyDateRange = true;

            for(let i=0; i<this.calendar.length; i++) {
                if (newVal === this.calendar[i].fullDate) {
                    this.calendar[i].selected = true;
                    isOverWeeklyDateRange = false;
                } else {
                    this.calendar[i].selected = false;
                }
            }

            this.$emit('isOverWeeklyDateRange',isOverWeeklyDateRange);
        },

        getDates(startDate,daysToAdd,isGenerateFutureDate) {
            let aryDates = [];
            let todayDate = new Date();
                for (let i = 0; i <= daysToAdd; i++) {
                    let currentDate = new Date();
                    if (isGenerateFutureDate) {
                       currentDate.setDate(startDate.getDate() + i);
                    } else {
                       currentDate.setDate(startDate.getDate() - i);
                    }

                    let obj ={};
                    let fullDateMonth = currentDate.getMonth()+1;
                    let fullDateDate = currentDate.getDate();
                    
                    if (fullDateMonth < 10) {
                        fullDateMonth = `0${fullDateMonth}`
                    }
                    if (fullDateDate < 10) {
                        fullDateDate = `0${fullDateDate}`
                    }
                    obj.selected = false;
                    obj.day = this.$tools.getDay(currentDate.getDay());
                    obj.month =this.$tools.getMonth(currentDate.getMonth()),
                    obj.date = currentDate.getDate();
                    
                    obj.fullDate =  `${currentDate.getFullYear()}-${fullDateMonth}-${fullDateDate}`;
                    if (currentDate.getDate() === todayDate.getDate() && currentDate.getMonth() === todayDate.getMonth() && currentDate.getYear() === todayDate.getYear()) {
                        if (isGenerateFutureDate || this.isShowPassDayOnly) {
                            obj.selected = true;
                            obj.day = TODAY;
                        }      
                    }
                  
                    aryDates.push(obj)
                    //aryDates.push(DayAsString(currentDate.getDay()) + ", " + currentDate.getDate() + " " + MonthAsString(currentDate.getMonth()) + " " + currentDate.getFullYear());
                }

                return aryDates;
        },
      
        initCalendar() {
            let isGenerateNextDate = true;
            let show = this.show;
            let currentDate = new Date();
            let prevDates = this.getDates(currentDate,show,!isGenerateNextDate);
            let nextDates = this.getDates(currentDate,show,isGenerateNextDate);
            let fullDates
            
            if (this.isShowPassDayOnly) {
                show = show * 2;
                prevDates = this.getDates(currentDate,show,!isGenerateNextDate);
                fullDates = prevDates.reverse();
            } else {
                prevDates.shift()//remove duplicate today; 
                fullDates = prevDates.reverse().concat(nextDates);
            }
        
            this.calendar = fullDates;     
            let scrollPosition = this.show / 2 * 60;

            if (this.isShowPassDayOnly) {
                scrollPosition = 500;
            }
      
             this.$nextTick(() => {
                this.$refs.calendarContent.scrollTo(scrollPosition, 0)   

            })   
    
       
        }
        
    }
}
</script>

<style>

.calendar-container{
    display:flex;
    border-bottom: .1rem solid var(--color-theme);
}
.calendar-box{
    position: relative;
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: .66rem;
    padding: .3rem .125rem .5rem 0.125rem;
    margin: 0 .3rem;
    min-width: 3rem;
    cursor:pointer;
    text-align:center;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    margin-bottom: .3rem;
    z-index: 1;
}
.calendar-box.selected{
    color : black !important;
    background-color: var(--bg-selected) !important;
}
.calendar-box:hover{
    color : var(--color-hover-20);
    background-color: var(--color-hover); 
}

.calendar-box.selected::after{
    bottom: -9.5px;
    left: 50%;
    border-style: solid;
    border-image: initial;
    content: " ";
    height: 0px;
    width: 0px;
    position: absolute;
    pointer-events: none;
    border-color: var(--bg-selected) rgba(255, 138, 0, 0) rgba(255, 138, 0, 0) ;
    border-width: 5px 7px;
    margin-left: -7px;
}

.calendar-box__week{
    font-weight: 700;
}   
.calendar-box__monnth{
    font-weight: 400;
}
  
@media (max-width: 768px){
    .calendar-blur{
        width: 2rem;
        height: 98%;
        top : 0;
        position: absolute;
        z-index: 2;
    }
    .calendar-blur.left{
        background: linear-gradient(90deg,var(--bg-theme),transparent);
        left: 0;
    }
    .calendar-blur.right{
        background: linear-gradient(90deg,transparent,var(--bg-theme));
        right: 0;
    }
   .calendar-wrapper{
        position: relative;
        width: 15rem;
      
    }  
    .calendar-container{
        overflow-x: auto;
        overflow-y: hidden;
    }
    .calendar-box:hover{
        color :white ;
        background-color: transparent; 
    }

}
</style>