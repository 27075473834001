<template>
  <router-link
    :to="{
      path: `/${currentLocale}/${currentSportType}/highlights/${
        parentData.countryKey
      }/${parentData.leagueKey}/${data.opponents[0].name.replace(/(\s|\/)/g, '-') +
        '-vs-' +
        data.opponents[1].name.replace(/(\s|\/)/g, '-')}/${data.matchId}`,
      query: { date: date },
    }"
    :target="target"
    class="home-match__card match-card__container"
  >
    <p class="home-match__time">{{ data.matchStatus }}</p>
    <div class="display-flex-fe flex-1">
      <div class="home-match__detail-wrapper">
        <div
          class="home-match__detail"
          v-for="(level3, index) in data.opponents"
          :key="index"
        >
          <div class="home-match__detail-opponent">
            <Icon :logoUrl="level3.logo" class="home-match__icon"></Icon>
            <span class="mr-03rem">{{ level3.name }}</span>
            <span class="icon-fav tiny" v-if="!!level3.isFavTeam"></span>
            <img
              class="ml-1rem mr-1rem"
              v-if="level3.penaltyWin"
              src="../../../static/images/label/label_pen_win.png"
            />
          </div>
          <div class="display-flex">
            <!-- <div class="home-match__detail-score" v-if="level3.penaltyScore">{{level3.penaltyScore}}</div> -->
            <div class="home-match__detail-score" v-show="currentSportType === 'football'">{{ level3.score }}</div>
            <div class="home-match__detail-score" v-show="currentSportType === 'basketball'">{{ level3.totalScore }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="match-card__more">
                <img src="../../../static/images/icons/icon_more.png">
            </div>  -->
    </div>
  </router-link>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["isMobile", "currentLocale", "currentSportType"]),
  },

  props: {
    data: {
      type: Object,
    },
    parentData: {
      type: Object,
    },
    classes: {
      type: String,
    },
    matchDetailScore: {
      type: Object,
    },
    target: {
      type: String,
      default: "_blank",
    },
    date: {
      type: String,
    },
  },

  data() {
    return {};
  },
};
</script>

<style>
.match-card__wrapper {
  width: 17rem;
  overflow-y: unset;
}
.match-card__container {
  display: block;
  padding: 0.5rem 0.8rem;
}
.match-card__container:hover {
  background-color: var(--color-grey-40);
  border-color: var(--color-grey-40);
}
.match-card__more {
  position: relative;
  margin: 0 0.2rem 0 0.8rem;
  cursor: pointer;
}
.match-card__more-popover {
  position: absolute;
  width: 10rem;
  padding: 0.8rem;
  background-color: var(--color-grey-40);
}
</style>
